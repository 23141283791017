import React from 'react';

 
import './thankyou.css'
import { Link } from 'react-router-dom';


const Thankyou = ()=>{
    return(

        <section className='thankyou-cont'>
             

             <div className="thanks-iner">

             <div className="item text-item">
                    <h3>Thank you for reaching out!</h3>
                    <p>We’ll get back to you shortly to discuss your requirements.".</p>

                    <div className='link'>
                        <Link to="/"> Back to home</Link>
                    </div>
            </div>

                <div className="item">
                <img src="../images/thankyou.jpg" className=' thanks-img ' alt="image" />
                </div>
                
             </div>

            
     
            
            </section>

        
    )
}


export default Thankyou;