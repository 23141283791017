"use client";

import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./StepperForm.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import BASE_URL from '../components/constant';
export const StepperForm = ( ) => {
     const navigate= useNavigate();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    location: "",
    businessAge: "",
    businessType: "",
    businessTypeOther: "",
    services: [],
    servicesOther: "",
    brandGrowth: "",
    budget: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    phone: "",
    email: "",
  });

  const steps = [
    { label: "What is your full name?", type: "text", name: "fullName" },
    {
      label: "What is your phone/WhatsApp number?",
      type: "phone",
      name: "phone",
    },
    { label: "What is your email address?", type: "email", name: "email" },
    {
      label: "Where are you located?",
      type: "select",
      name: "location",
      options: [
        "Andhra Pradesh",
        "Delhi",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
    },
    {
      label: "How old is your business?",
      type: "radio",
      name: "businessAge",
      options: [
        "Yet to start",
        "Less than 1 year",
        "1-3 years",
        "3-5 years",
        "More than 5 years",
      ],
    },
    {
      label: "What type of business do you have?",
      type: "radio",
      name: "businessType",
      options: [
        {
          label: "E-commerce",
          desc: "(Clothing, Electronics, Food, etc.)",
        },
        {
          label: "Retail Store",
          desc: "(Supermarket, Boutique, Pharmacy, etc.)",
        },
        {
          label: "Manufacturing",
          desc: "(Food, Apparel, Machinery, etc.)",
        },
        {
          label: "Service-Based",
          desc: "(Marketing, Consulting, IT Services, etc.)",
        },
        {
          label: "Hospitality",
          desc: "(Restaurant, Café, Hotel, etc.)",
        },
        {
          label: "Healthcare",
          desc: "(Clinic, Pharmacy, Wellness, etc.)",
        },
        {
          label: "Education",
          desc: "(School, Coaching Center, Online Courses, etc.)",
        },
        {
          label: "Real Estate",
          desc: "(Property Dealer, Construction, Rentals, etc.)",
        },
        {
          label: "Finance & Legal",
          desc: "(Accounting, Law Firm, Tax Services, etc.)",
        },
        {
          label: "Other",
          desc: "",
        },
      ],
    },
    {
      label: "What services do you need?",
      type: "checkbox",
      name: "services",
      options: [
        {
          label: "Website Development/App Development",
          desc: "",
        },
        {
          label: "Graphic Design",
          desc: "(Logos, Brochures, Social Media Posts, etc.)",
        },
        {
          label: "Video Editing & Animation",
          desc: "(2D/3D Animation, Reels, Ads, etc.)",
        },
        {
          label: "Social Media Management",
          desc: "(Instagram, Facebook, LinkedIn, etc.)",
        },
        {
          label: "Digital Marketing",
          desc: "(SEO, Google Ads, Facebook Ads, etc.)",
        },
        {
          label: "Branding & Identity",
          desc: "(Packaging Design, Brand Strategy, etc.)",
        },
        {
          label: "E-commerce Solutions",
          desc: "(Shopify, WooCommerce, Custom Stores, etc.)",
        },
        {
          label: "Content Writing",
          desc: "(Blogs, Website Content, Product Descriptions, etc.)",
        },
        {
          label: "Chatbot & AI Solutions",
          desc: "(Customer Support Automation, AI Integration, etc.)",
        },
        {
          label: "Other",
          desc: "",
        },
      ],
    },
    {
      label: "How big do you want to build your brand in the next 3 years?",
      type: "radio",
      name: "brandGrowth",
      options: [
        {
          label: "Small & Local",
          desc: "(Serving a niche or local market)",
        },
        {
          label: "Medium-Sized",
          desc: "(Expanding regionally with a steady customer base)",
        },
        {
          label: "Large & National",
          desc: "(Becoming a well-known brand across the country)",
        },
        {
          label: "Global Brand",
          desc: "(Expanding internationally with a strong presence)",
        },
        {
          label: "Not sure yet",
          desc: "",
        },
      ],
    },
    {
      label: "What is your estimated budget for this project?",
      type: "radio",
      name: "budget",
      options: [
        "Less than ₹10,000",
        "₹10,000 - ₹50,000",
        "₹50,000 - ₹1,00,000",
        "₹1,00,000 - ₹5,00,000",
        "More than ₹5,00,000",
        "Not sure yet",
      ],
    },
  ];

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const validatePhone = (phone) => {
    if (!phone) {
      return "Phone number is required";
    }
    if (phone.length < 10) {
      return "Please enter a valid phone number";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const isCheckbox = type === "checkbox";

    if (isCheckbox) {
      setFormData((prev) => ({
        ...prev,
        [name]: e.target.checked
          ? [...prev[name], value]
          : prev[name].filter((v) => v !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));

      // Validate email on change
      if (name === "email") {
        setValidationErrors((prev) => ({
          ...prev,
          email: validateEmail(value),
        }));
      }
    }
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, phone: value }));
    setValidationErrors((prev) => ({
      ...prev,
      phone: validatePhone(value),
    }));
  };

  const handleNext = () => {
    const currentField = steps[step].name;

    if (currentField === "email") {
      const emailError = validateEmail(formData.email);
      setValidationErrors((prev) => ({ ...prev, email: emailError }));
      if (emailError) return;
    }

    if (currentField === "phone") {
      const phoneError = validatePhone(formData.phone);
      setValidationErrors((prev) => ({ ...prev, phone: phoneError }));
      if (phoneError) return;
    }

    if (validateCurrentStep()) {
      setStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setStep((prev) => prev - 1);

  const validateCurrentStep = () => {
    const currentField = steps[step].name;
    const currentValue = formData[currentField];

    if (steps[step].type === "checkbox") {
      return true; // Checkboxes are optional
    }

    if (currentField === "businessType" && currentValue === "Other") {
      return Boolean(formData.businessTypeOther);
    }

    if (currentField === "email" && validationErrors.email) {
      return false;
    }

    if (currentField === "phone" && validationErrors.phone) {
      return false;
    }

    return Boolean(currentValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateCurrentStep()) {
      return;
    }

    // Prepare the data for API submission
    const servicesString = formData.services.join(", ") + 
      (formData.servicesOther ? `, ${formData.servicesOther}` : "");
    
    const businessType = formData.businessType === "Other" 
      ? formData.businessTypeOther 
      : formData.businessType;

    const userData = {
      client_email: formData.email,
      client_name: formData.fullName,
      message: `
Business Details:
- Location: ${formData.location}
- Business Age: ${formData.businessAge}
- Business Type: ${businessType}
- Required Services: ${servicesString}
- Brand Growth Goal: ${formData.brandGrowth}
- Budget Range: ${formData.budget}
      `,
      phone: formData.phone,
      budget: formData.budget,
      currency: "INR",
      // You might need to adjust these based on your backend requirements
      category_id: "1", // Default category
      service_name: servicesString,
      scheduled_date: new Date().toISOString().split('T')[0],
      scheduled_time: new Date().toTimeString().split(' ')[0]
    };

    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/meetings_api.php`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        const dataTosend = {
          date: userData.scheduled_date,
          time: userData.scheduled_time
        };
        setFormData({
            fullName: "",
      phone: "",
      email: "",
      location: "",
      businessAge: "",
      businessType: "",
      businessTypeOther: "",
      services: [],
      servicesOther: "",
      brandGrowth: "",
      budget: "",
  
          })
        navigate('/Pages/meeting', { state: dataTosend });
        // If you're using Firebase, uncomment this:
        // addDataToFirestore(`${formData.fullName} submitted form on ${userData.scheduled_date}`);
      } else {
        setLoading(false);
        console.error('API Request failed with status:', response.status);
        alert('An error occurred while processing your request. Please try again later.');
      }
    } catch (error) {
      setLoading(false);
      console.error('An unexpected error occurred:', error.message);
      alert('An unexpected error occurred. Please try again later. ' + error.message);
    }
  };

  const progressPercentage = ((step + 1) / steps.length) * 100;

  const renderOtherInput = (fieldName, otherFieldName) => {
    if (
      (fieldName === "businessType" && formData.businessType === "Other") ||
      (fieldName === "services" && formData.services.includes("Other"))
    ) {
      return (
        <input
          type="text"
          name={otherFieldName}
          value={formData[otherFieldName]}
          onChange={handleChange}
          placeholder="Please specify"
          className="text-input margin-top-1"
          required
        />
      );
    }
    return null;
  };

  const renderOptionLabel = (opt) => {
    if (typeof opt === "string") {
      return <span className="option-text">{opt}</span>;
    }
    return (
      <span className="option-text">
        <span className="option-labell-text">{opt.label}</span>
        {opt.desc && <span className="option-description">{opt.desc}</span>}
      </span>
    );
  };

  const renderInput = (step) => {
    if (step.type === "phone") {
      return (
        <div className="phone-input-containerr">
          <PhoneInput
            country={"in"}
            value={formData.phone}
            onChange={handlePhoneChange}
            inputClass="phone-input"
            containerClass="phone-input-wrapper"
            dropdownClass="country-dropdown"
            searchClass="search-dropdown"
            enableSearch={true}
            disableSearchIcon={false}
            searchPlaceholder="Search country..."
          />
          {validationErrors.phone && (
            <div className="error-message">{validationErrors.phone}</div>
          )}
        </div>
      );
    }

    if (step.type === "text" || step.type === "email") {
      return (
        <div className="input-wrapper">
          <input
            type={step.type}
            name={step.name}
            value={formData[step.name]}
            onChange={handleChange}
            required
            className="text-input"
          />
          {step.type === "email" && validationErrors.email && (
            <div className="error-message">{validationErrors.email}</div>
          )}
        </div>
      );
    }

    if (step.type === "select") {
      return (
        <select
          name={step.name}
          value={formData[step.name]}
          onChange={handleChange}
          required
          className="select-input"
        >
          <option value="">Select your location</option>
          {step.options.map((opt, index) => (
            <option
              key={index}
              value={typeof opt === "string" ? opt : opt.label}
            >
              {typeof opt === "string" ? opt : opt.label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <div className="options-gridd">
        {step.options.map((opt, index) => (
          <label key={index} className="option-labell">
            <input
              type={step.type}
              name={step.name}
              value={typeof opt === "string" ? opt : opt.label}
              checked={
                step.type === "checkbox"
                  ? formData[step.name].includes(
                      typeof opt === "string" ? opt : opt.label
                    )
                  : formData[step.name] ===
                    (typeof opt === "string" ? opt : opt.label)
              }
              onChange={handleChange}
              className={`${step.type}-input`}
            />
            {renderOptionLabel(opt)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="stepper-container">
      <div className="progress-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className="step-indicator">
          Step {step + 1} of {steps.length}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="form-box">
        <div className="question-container">
          <h2 className="question-label">{steps[step].label}</h2>

          <div className="input-containerr">
            {renderInput(steps[step])}
            {renderOtherInput(
              steps[step].name,
              steps[step].name === "businessType"
                ? "businessTypeOther"
                : "servicesOther"
            )}
          </div>
        </div>

        <div className="button-group">
          {step > 0 && (
            <button type="button" onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          {step < steps.length - 1 ? (
            <button
              type="button"
              onClick={handleNext}
              className="next-button"
              disabled={!validateCurrentStep()}
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="submit-button"
              disabled={!validateCurrentStep()}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default StepperForm;
