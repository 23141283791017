import React, {useState,useEffect} from "react";
import { BrowserRouter , Router, Routes, Route, NavLink, Switch, Link  } from "react-router-dom";
import "./Footer.css";
import TopRated from "./Pages/TopRated";
import About from "./Pages/About";
import axios from 'axios'
import BASE_URL from '../src/components/constant';

const Footer =() =>{
    const [activeTab, setActiveTab] = useState(10);
    const [contacts, setContact] = useState([]);


    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };

    const [isContentVisible, setContentVisible] = useState(true);

    const handleTbClick = () => {
      setContentVisible((prev) => !prev);
    };
    const [isContentVisible2, setContentVisible2] = useState(false);

    const handleTbClick2 = () => {
      setContentVisible2((prev) => !prev);
    };
    const [isContentVisible3, setContentVisible3] = useState(false);

    const handleTbClick3 = () => {
      setContentVisible3((prev) => !prev);
    };
    const [isContentVisible4, setContentVisible4] = useState(false);

    const handleTbClick4 = () => {
      setContentVisible4((prev) => !prev);
    };
    const [isContentVisible5, setContentVisible5] = useState(false);
    const handleTbClick5 = () => {
      setContentVisible5((prev) => !prev);
    };
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: for smooth scrolling
      });
    };
    useEffect(()=>{
      axios.get(`${BASE_URL}/general_settings.php`)
      .then(res => {
        console.log(res.data);
        setContact(res.data); // Assuming the response has a services array
      })
      .catch(err=>console.log(err));
      },[])
      const getContact = (index) => {
        console.log(contacts);
        if (contacts!=null ) {
          return contacts;
        }else{
        return {contact_no: "123456789",
        whatsapp_no: "987654321",
        address: "123 Main Street",
        email: "example@example.com",
        fb: "facebook",
        insta: "instagram",
        twitter: "twitter",
        linkedin: "linkedin",
        youtube: "youtube"};}; 
      };
  
    return(
        <>
        <footer className="mafooter">
            <div className="main-footer md:block ipadAirFlex flex">
            <div className="logo ftlogo">
                <img src="/../images/logo.png"/>
                <p class="text-center text-[13.5px] text-red-400 underline w-auto font-lato font-semibold">Set Your Worth To The World</p>
            </div>
            <div className="mobile">
                <TopRated/>
            </div>

            <div className="tablet">
                <TopRated/>
            </div>
            <div className="tablet px-[15px] ipadAirnone" style={{  }}>
{/*  Tablet */}
<div
      className="w-[100%] mb-[2%]"
      onClick={handleTbClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg font-extrabold	  text-red-A200  font-lato">Company</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible && (
        <div className="mb-5 ml-2" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
         
            <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-[17px] text-xs" to="/Pages/About">About Us</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Services">Services</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Why">Why Us</Link></li>
            </ul>
        </div>
      )}
      {/* 2 */}
      <div
      className="w-[100%] mb-[2%]"
      onClick={handleTbClick2}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible2 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg  text-red-A200 font-extrabold	 font-lato">Services</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible2 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible2 && (
        <div className="mb-5" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
           <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/1/Full Website Creation">Programming & Tech</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/2/Articles & Blog Posts">Writing & Translation</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/3/Video Editing">Video & Animation</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/4/Logo Design">Graphics & Design</Link></li>

            </ul>
        </div>
      )}

      {/* 5 */}
      {/* 2 */}
      <div
      className="w-[100%] mb-[2%]"
      onClick={handleTbClick5}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible2 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg  text-red-A200 font-extrabold	 font-lato">Resources</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible2 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible5 && (
        <div className="mb-5" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
           <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/BlogsPost">Blogs</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Terms">Terms of Conditions</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Policy">Privacy Policy</Link></li>
            </ul>
        </div>
      )}

      {/* 3 */}
      <div
      className="w-[100%] mb-[2%]"
      onClick={handleTbClick3}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible3 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg text-red-A200 font-extrabold	 font-lato">Contact US</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible3 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible3 && (
        <div className="mb-5" style={{ marginTop: '-10px', padding: '1px', }}>
          {/* Your content goes here */}
          <ul>
          <li className="mt-2"><a className="text-gray-600 text-xs flex" href= {`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`}><div className="bg-green-A700 rounded-[11px] p-[4px]"><img className="" src="/../images/img_volume.svg"/></div><span className="cl-links">{`+${getContact(0).whatsapp_no}`}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`tel:${getContact(0).contact_no}`}><div className="bg-blue_gray-900_02 rounded-[11px] p-[5px]"><img className="" src="/../images/img_call.svg"/></div><span className="cl-links">{getContact(0).contact_no}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`mailto:${getContact(0).email}`}><div className="bg-red-400 rounded-[11px] p-[4.5px] ml-[-1px]"><img className="" src="/../images/img_lock.svg"/></div><span className="cl-links">{getContact(0).email}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href=""><div className=""><img style={{backgroundColor:"rgb(31, 65, 104)"}} className="rounded-[11px] p-[3px] ml-[0px]" src="/../images/img_linkedin.svg"/></div><span className="cl-links mt-[3px] ml-[9px]">{getContact(0).address}</span></a></li>
     
                {/* <li className="mt-2"><a className="text-gray-600 text-xs flex" href="https://api.whatsapp.com/send?phone=+919315269100"><div className="bg-green-A700 rounded-[11px] p-[5px]"><img className="" src="/../images/img_volume.svg"/></div><span className="cl-links">+919315269100</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href="tel:+919315269100"><div className="bg-blue_gray-900_02 rounded-[11px] p-[5px]"><img className="" src="/../images/img_call.svg"/></div><span className="cl-links">+919315269100</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs ml-[-1px] flex" href="contactultravux@gmail.com"><img className="bg-red-400 rounded-[11px] p-[5px]" src="/../images/img_lock.svg"/><span className="cl-links">contactultravux@gmail.com</span></a></li> */}
                {/* <li className="mt-2"><a className="text-gray-600 text-xs mt-[-5px] flex" href=""><div className="rounded-[11px] p-[5px] ml-[-4px] mt-[0px]"><img className="" src="/../images/img_linkedin.svg"/></div><span className="cl-links mt-[10px] ml-[3px]">New Delhi, India</span></a></li> */}
            </ul>
        </div>
      )}
      {/* 4 */}
      <div
      className="w-[100%] mb-[2%]"
      onClick={handleTbClick4}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible4 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg text-red-A200 font-extrabold	 font-lato">Social Links</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible4 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible4 && (
        <div className="mb-5" style={{ marginTop: '10px', padding: '1px', }}>
          {/* Your content goes here */}
          <ul className="flex flex-row gap-[11.25px] items-start justify-start mt-2">
                        {/* <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-red-400 "><img src="/../images/img_info.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-light_blue-500 "><img src="/../images/img_dashiconsfacebookalt.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"><img src="/../images/img_bxllinkedin.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-gray-900"><img src="/../images/img_antdesigntwitteroutlined.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-red-400"><img src="/../images/img_biyoutube.svg"/></a></li> */}
                        <li><a href={getContact(0).insta} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-red-400 "><img src="/../images/img_info.svg"/></a></li>
                        <li><a href={getContact(0).fb} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-light_blue-500 "><img src="/../images/img_dashiconsfacebookalt.svg"/></a></li>
                        <li><a href={getContact(0).linkedin} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"><img src="/../images/img_bxllinkedin.svg"/></a></li>
                        <li><a href={getContact(0).twitter} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-gray-900"><img src="/../images/img_antdesigntwitteroutlined.svg"/></a></li>
                        <li><a href={getContact(0).youtube} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-red-400"><img src="/../images/img_biyoutube.svg"/></a></li>
                 

                    </ul>
        </div>
      )}


        </div>
        {/*  Tablet */}
            <div className="mobile px-[15px]" style={{  }}>
{/* mobile Tab */}
<div
      className="w-[100%] mb-[6%]"
      onClick={handleTbClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg font-extrabold	  text-red-A200  font-lato">Company</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible && (
        <div className="mb-5 ml-2" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
         
            <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-[17px] text-xs" to="/Pages/About">About Us</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Services">Services</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Why">Why Us</Link></li>
            </ul>
        </div>
      )}
      {/* 2 */}
      <div
      className="w-[100%] mb-[6%]"
      onClick={handleTbClick2}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible2 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg  text-red-A200 font-extrabold	 font-lato">Services</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible2 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible2 && (
        <div className="mb-5" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
           <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/1/Full Website Creation">Programming & Tech</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/2/Articles & Blog Posts">Writing & Translation</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/3/Video Editing">Video & Animation</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Service/4/Logo Design">Graphics & Design</Link></li>

            </ul>
        </div>
      )}

      {/* 5 */}
      {/* 2 */}
      <div
      className="w-[100%] mb-[6%]"
      onClick={handleTbClick5}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible2 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg  text-red-A200 font-extrabold	 font-lato">Resources</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible2 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible5 && (
        <div className="mb-5" style={{ marginTop: '-14px', padding: '1px', }}>
          {/* Your content goes here */}
           <ul>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/BlogsPost">Blogs</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Terms">Terms of Conditions</Link></li>
                <li onClick={scrollToTop} className="mt-2"><Link className="text-gray-600 text-xs text-[17px]" to="/Pages/Policy">Privacy Policy</Link></li>
            </ul>
        </div>
      )}

      {/* 3 */}
      <div
      className="w-[100%] mb-[6%]"
      onClick={handleTbClick3}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible3 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg text-red-A200 font-extrabold	 font-lato">Contact US</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible3 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible3 && (
        <div className="mb-5" style={{ marginTop: '-10px', padding: '1px', }}>
          {/* Your content goes here */}
          <ul>
          <li className="mt-2"><a className="text-gray-600 text-xs flex" href= {`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`}><div className="bg-green-A700 rounded-[11px] p-[4px]"><img className="" src="/../images/img_volume.svg"/></div><span className="cl-links">{`+${getContact(0).whatsapp_no}`}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`tel:${getContact(0).contact_no}`}><div className="bg-blue_gray-900_02 rounded-[11px] p-[5px]"><img className="" src="/../images/img_call.svg"/></div><span className="cl-links">{getContact(0).contact_no}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`mailto:${getContact(0).email}`}><div className="bg-red-400 rounded-[11px] p-[4.5px] ml-[-1px]"><img className="" src="/../images/img_lock.svg"/></div><span className="cl-links">{getContact(0).email}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href=""><div className=""><img style={{backgroundColor:"rgb(31, 65, 104)"}} className="rounded-[11px] p-[3px] ml-[0px]" src="/../images/img_linkedin.svg"/></div><span className="cl-links mt-[3px] ml-[9px]">{getContact(0).address}</span></a></li>
     
                {/* <li className="mt-2"><a className="text-gray-600 text-xs flex" href="https://api.whatsapp.com/send?phone=+919315269100"><div className="bg-green-A700 rounded-[11px] p-[5px]"><img className="" src="/../images/img_volume.svg"/></div><span className="cl-links">+919315269100</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href="tel:+919315269100"><div className="bg-blue_gray-900_02 rounded-[11px] p-[5px]"><img className="" src="/../images/img_call.svg"/></div><span className="cl-links">+919315269100</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs ml-[-1px] flex" href="contactultravux@gmail.com"><img className="bg-red-400 rounded-[11px] p-[5px]" src="/../images/img_lock.svg"/><span className="cl-links">contactultravux@gmail.com</span></a></li> */}
                {/* <li className="mt-2"><a className="text-gray-600 text-xs mt-[-5px] flex" href=""><div className="rounded-[11px] p-[5px] ml-[-4px] mt-[0px]"><img className="" src="/../images/img_linkedin.svg"/></div><span className="cl-links mt-[10px] ml-[3px]">New Delhi, India</span></a></li> */}
            </ul>
        </div>
      )}
      {/* 4 */}
      <div
      className="w-[100%] mb-[6%]"
      onClick={handleTbClick4}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        cursor: 'pointer',
        borderBottom: isContentVisible4 ? '2px solid #f5e5e6' : '2px solid transparent',
        background: 'rgba(236, 79, 92, 0.10)',
      }}
    >
     
      <p className="text-left capitalize w-[95%] text-lg text-red-A200 font-extrabold	 font-lato">Social Links</p>
      {/* Arrow */}
      <img
      style={{width: "15.997px", height: "8px",}}
       className={`arrow ${isContentVisible4 ? 'up' : 'down'}`} src="/../images/drop-down.png"/>
      {/* Content */}

    </div>
    {isContentVisible4 && (
        <div className="mb-5" style={{ marginTop: '10px', padding: '1px', }}>
          {/* Your content goes here */}
          <ul className="flex flex-row gap-[11.25px] items-start justify-start mt-2">
                        {/* <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-red-400 "><img src="/../images/img_info.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-light_blue-500 "><img src="/../images/img_dashiconsfacebookalt.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"><img src="/../images/img_bxllinkedin.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-gray-900"><img src="/../images/img_antdesigntwitteroutlined.svg"/></a></li>
                        <li><a href="" className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-red-400"><img src="/../images/img_biyoutube.svg"/></a></li> */}
<li><a href={getContact(0).insta} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-red-400 "><img src="/../images/img_info.svg"/></a></li>
                        <li><a href={getContact(0).fb} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-light_blue-500 "><img src="/../images/img_dashiconsfacebookalt.svg"/></a></li>
                        <li><a href={getContact(0).linkedin} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"><img src="/../images/img_bxllinkedin.svg"/></a></li>
                        <li><a href={getContact(0).twitter} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-gray-900"><img src="/../images/img_antdesigntwitteroutlined.svg"/></a></li>
                        <li><a href={getContact(0).youtube} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-red-400"><img src="/../images/img_biyoutube.svg"/></a></li>
                 

                    </ul>
        </div>
      )}


        </div>
        {/* Mobile Tab */}
            <div className="footer-links md:block desktop ipadAirFlex flex mt-[3%]">
            <div className="links">
            <p class="text-left capitalize text-lg text-red-A200 font-bold font-lato">Company</p>
            <ul>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/About">About Us</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Services">Services</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Why">Why Us</Link></li>
            </ul>
            </div>
            <div className="links">
            <p class="text-left capitalize text-lg text-red-A200 font-bold font-lato">Services</p>
            <ul>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Service/1/Full Website Creation">Programming & Tech</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Service/2/Articles & Blog Posts">Writing & Translation</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Service/3/Video Editing">Video & Animation</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Service/4/Logo Design">Graphics & Design</Link></li>
            </ul>
            </div>
            <div className="links">
            <p class="text-left capitalize text-lg text-red-A200 font-bold font-lato">Resources</p>
            <ul>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/BlogsPost">Blogs</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Terms">Terms of Conditions</Link></li>
                <li onClick={scrollToTop}><Link className="text-gray-600 text-xs" to="/Pages/Policy">Privacy Policy</Link></li>
            </ul>
            </div>
            <div className="links">
            <p class="text-left capitalize text-lg text-red-A200 font-bold font-lato">Resources</p>
            <ul className="w-[250px]">
            <li className="mt-2"><a className="text-gray-600 text-xs flex" href= {`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`}><div className="bg-green-A700 rounded-[11px] p-[4px]"><img className="" src="/../images/img_volume.svg"/></div><span className="cl-links">{`+${getContact(0).whatsapp_no}`}</span></a></li>
                   <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`tel:${getContact(0).contact_no}`}><div className="bg-blue_gray-900_02 rounded-[11px] p-[5px]"><img className="" src="/../images/img_call.svg"/></div><span className="cl-links">{getContact(0).contact_no}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href={`mailto:${getContact(0).email}`}><div className="bg-red-400 rounded-[11px] p-[4.5px] ml-[-1px]"><img className="" src="/../images/img_lock.svg"/></div><span className="cl-links">{getContact(0).email}</span></a></li>
                <li className="mt-2"><a className="text-gray-600 text-xs flex" href=""><div className=""><img style={{backgroundColor:"rgb(31, 65, 104)"}} className="rounded-[11px] p-[3px] ml-[0px]" src="/../images/img_linkedin.svg"/></div><span className="cl-links mt-[3px] ml-[9px]">{getContact(0).address}</span></a></li>
                <li>
                    <ul className="flex flex-row gap-[11.25px] items-start justify-start mt-2">
                        <li><a href={getContact(0).insta} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-red-400 "><img src="/../images/img_info.svg"/></a></li>
                        <li><a href={getContact(0).fb} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-light_blue-500 "><img src="/../images/img_dashiconsfacebookalt.svg"/></a></li>
                        <li><a href={getContact(0).linkedin} className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"><img src="/../images/img_bxllinkedin.svg"/></a></li>
                        <li><a href={getContact(0).twitter} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-gray-900"><img src="/../images/img_antdesigntwitteroutlined.svg"/></a></li>
                        <li><a href={getContact(0).youtube} className="flex h-6 items-center justify-center w-6 rounded-[50%] p-1 bg-red-400"><img src="/../images/img_biyoutube.svg"/></a></li>
                    </ul>
                </li>


            </ul>
            
</div>
            </div>
            </div>
            <div className="fotter-btm">
              <p> All Right Reserved 2025 | ULTRAVUX PRIVATE LIMITED.</p>
            </div>
        </footer>
        </>
    )
}


export default Footer;